<template>
  <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
    <div class="container_home w-container">
      <nav role="navigation" class="nav-menu w-nav-menu">
        <a href="https://www.sosclick.cl/" target="_blank" class="nav-link2-2 w-nav-link">Sosclick</a>
        <a href="https://www.sosclickmujer.com/" target="_blank" class="nav-link2-2 w-nav-link">SosclickMujer</a>
        <a href="https://www.iduam.com/" target="_blank" class="nav-link2-2 w-nav-link">IDUAM</a>
      </nav>
      <a href="#" class="brand w-nav-brand"><img src="@/assets/images/Logo.svg" loading="lazy" width="112" alt="" class="image-8"></a>
      <div class="menu-button w-nav-button">
        <div class="icon w-icon-nav-menu"></div>
      </div>
      <div class="div-block-16"></div>
      <div class="div-block-17"><img src="@/assets/images/iconos-pantallas.svg" loading="lazy" width="208" alt="" class="image-9"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    isMobile: false,
  }),
  // beforeDestroy() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("resize", this.onResize, { passive: true });
  //   }
  // },
  // mounted() {
  //   this.onResize();
  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },
  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 992;
  //   },
  // },
};
</script>
<style>
a {
  text-decoration: none !important;
  color: #fff !important;
}

</style>
