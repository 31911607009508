
<template>
  <div>

    <div class="section wf-section">
      <div class="div-block">
        <h1 class="heading-principal">SOSCLICK<span class="text-span">LITE</span></h1>
        <div class="div-block-2">
          <p class="paragraph">Se alza como un Servicio único en el ámbito de la Seguridad, que ofrece un abanico
            de características que se activan al momento en que el usuario pronuncie una palabra clave, la cual servirá de
            detonante para que el Sistema se active y de forma inmediata comience a grabar y transmitir vía Internet a un
            servidor de nuestra empresa, el video instantáneo que es capturado por la Aplicación <strong>“SOSCLICKLITE”</strong>,
            que estará previamente descargada en el smartphone que se le entregará a cada usuario que contrate nuestros servicios.</p>
          <div class="div-block-4">
            <a href="#" class="button w-button">Contratar Servicio</a>
            <a href="#" class="button-claro w-button">Solicitar mas información</a>
            <a @click="changeVideo" class="button-claro w-button">Ver Video</a>
          </div>
          <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 99999 !important;
              "
              v-if="video"
              @click="changeVideo"

          >
            <div
                class="w-lightbox-backdrop"
                style="transition: opacity 300ms ease 0s; opacity: 1"
            >
              <div class="w-lightbox-container">
                <div class="w-lightbox-content">
                  <div
                      class="w-lightbox-view"
                      tabindex="0"
                      id="w-lightbox-view"
                      style="opacity: 1"
                  >
                    <div class="w-lightbox-frame">
                      <figure class="w-lightbox-figure">
                        <v-progress-circular
                            :size="50"
                            :width="7"
                            color="white"
                            indeterminate
                            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                        ></v-progress-circular>
                        <img
                            class="w-lightbox-img w-lightbox-image"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%22940%22%20height=%22528%22/%3E"
                        />
                        <iframe
                            class="embedly-embed w-lightbox-embed"
                            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F0KAVHQcfy-w%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D0KAVHQcfy-w%26rel%3D0%26enablejsapi%3D1&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRW_EclTXHxk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                            width="940"
                            height="528"
                            scrolling="no"
                            title="YouTube embed"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen="true"
                        >

                        </iframe>
                      </figure>
                    </div>
                  </div>
                  <div
                      class="w-lightbox-spinner w-lightbox-hide"
                      role="progressbar"
                      aria-live="polite"
                      aria-hidden="true"
                      aria-busy="false"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="100"
                      aria-valuetext="Loaded image"
                  ></div>
                  <div
                      class="
                        w-lightbox-control w-lightbox-left w-lightbox-inactive
                      "
                      role="button"
                      aria-hidden="true"
                      aria-controls="w-lightbox-view"
                      aria-label="previous image"
                      tabindex="-1"
                  ></div>
                  <div
                      class="
                        w-lightbox-control w-lightbox-right w-lightbox-inactive
                      "
                      role="button"
                      aria-hidden="true"
                      aria-controls="w-lightbox-view"
                      aria-label="next image"
                      tabindex="-1"
                  ></div>
                  <div
                      class="w-lightbox-control w-lightbox-close"
                      role="button"
                      aria-label="close lightbox"
                      tabindex="0"
                      @click="changeVideo"
                  ></div>
                </div>
                <div class="w-lightbox-strip" role="tablist"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-w-id="8c1cb34e-91b4-1daf-79d2-1aed7b372618" style="opacity:1" class="div-block-3"></div>
    </div>
    <div class="section-2 wf-section">
      <div class="div-block-6">
        <div id="w-node-_596f67de-fb83-aa67-61d4-e61dbe7c6e16-c72c0137" data-w-id="596f67de-fb83-aa67-61d4-e61dbe7c6e16" style="opacity:1" class="div-block-5">
          <div class="div-block-7"><img src="@/assets/images/icon-logo.png" loading="lazy" alt="" class="image"></div>
          <p class="paragraph-3">Activación de la<br> Grabación</p>
        </div>
        <div id="w-node-_21b21ff4-2dd7-3566-3304-de72d84f8d6f-c72c0137" data-w-id="21b21ff4-2dd7-3566-3304-de72d84f8d6f" style="opacity:1" class="div-block-5">
          <div class="div-block-7"><img src="@/assets/images/icon-cam.png" loading="lazy" alt="" class="image"></div>
          <p class="paragraph-3">Transmisión a <br>nuestros Servidores</p>
        </div>
        <div id="w-node-_20013f59-23c6-f5f3-b0c4-6168447e932e-c72c0137" data-w-id="20013f59-23c6-f5f3-b0c4-6168447e932e" style="opacity:1" class="div-block-5">
          <div class="div-block-7"><img src="@/assets/images/icon-screen.png" loading="lazy" alt="" class="image"></div>
          <p class="paragraph-3">Almacenamiento<br>de pruebas</p>
        </div>
        <div id="w-node-_895735f4-b0f4-8deb-c76c-f25ab7c46555-c72c0137" data-w-id="895735f4-b0f4-8deb-c76c-f25ab7c46555" style="opacity:1" class="div-block-5">
          <div class="div-block-7"><img src="@/assets/images/icon-phone.png" loading="lazy" alt="" class="image"></div>
          <p class="paragraph-3">Alerta Sonora</p>
        </div>
        <div id="w-node-a276e345-3d95-4c06-e0b5-fb4735f8bce0-c72c0137" data-w-id="a276e345-3d95-4c06-e0b5-fb4735f8bce0" style="opacity:1" class="div-block-5">
          <div class="div-block-7"><img src="@/assets/images/icon-people.png" loading="lazy" alt="" class="image"></div>
          <p class="paragraph-3">Aviso a mi Red <br>de Seguridad</p>
        </div>
        <div id="w-node-_5b9049a2-55bd-a8fb-7132-5d5ab30b7842-c72c0137" data-w-id="5b9049a2-55bd-a8fb-7132-5d5ab30b7842" style="opacity:1" class="div-block-5">
          <div class="div-block-7"><img src="@/assets/images/icon-hand.png" loading="lazy" alt="" class="image"></div>
          <p class="paragraph-3">Disuasión <br>Delictiva</p>
        </div>
        <div id="w-node-fa8d8bb3-45a5-cd97-863a-fd9797aceb1c-c72c0137">
          <h1 class="heading-2 cf-benefits-pointer-number">SOSCLICKLITE</h1>
          <p class="paragraph-4">Adicionalmente a la activación de la grabación y transmisión del video a nuestros servidores
            el cual servirá de prueba ante los tribunales de justicia, se activará también una alarma sonora disuasiva y en forma
            simultánea el sistema enviará notificaciones de emergencia a las diferentes personas, familiares, amigos, locatarios,
            vecinos, etc, que componen la red propia de seguridad que tenga generada cada usuario, los cuales mediante un solo
            click en la notificación recibida podrán comunicarse con la víctima por una simple video llamada. <br></p>
        </div>
      </div>
      <div class="div-block-8">
        <p class="paragraph-5">Además de las notificaciones enviadas a la red de seguridad personal del usuario, el sistema
          también enviara una notificación de Alarma al Panel WEB de comunicación total que podrá estar instalado ya sea en 
          dependencias de Seguridad Ciudadana Comunal, Policía, etc, con el objeto de que estos funcionarios también se comuniquen
          por Video Llamada con la víctima, generándose así una comunicación Multipunto simultánea de Video Conferencia,
          pudiendo brindarle la ayuda y contención necesaria e inmediata que el usuario necesite ante cualquier acto delictual o
          emergencia personal que éste tenga. </p>
      </div>
    </div>


    <div class="section-3 wf-section">

<!--      <div class="div-block-9">-->
        <img src="@/assets/images/Cel-3.png" loading="lazy" data-w-id="175dd934-01c0-c16d-933f-7ad9cb952b21" srcset="@/assets/images/Cel-3-p-500.png 500w, @/assets/images/Cel-3.png 520w" sizes="100vw" alt="" class="image-2-copy">
        <img src="@/assets/images/Cel-4.png" loading="lazy" data-w-id="0f1fcab4-7af6-5ca1-7783-74fda0c1739e" alt="" class="image-2">
        <div data-w-id="76a302c5-d34e-377e-62d9-8908baacc0c7" class="div-block-12">
          <div class="div-block-10">
            <h1 data-w-id="8ba56cfc-240b-66aa-9003-860c66fa5b3e" style="opacity:1" class="heading-2 _2">Panel WEB<br>SOSCLICKLITE</h1>
          </div>
          <div class="div-block-11">
            <p data-w-id="4263c2c7-27de-c33e-4ef7-f60bcb68bbdf" style="opacity:1" class="paragraph-6">Se debe destacar que este Panel WEB siempre podrá
              si así se determina, ver la ubicación o geolocalización de cada persona que tenga el Sistema <strong>“SOSCLICKLITE”</strong> pudiendo incluso
              llamarlos por video llamada aún cuando estos no hayan generado ningún tipo de alerta. <br><br>Esta última funcionalidad debería ser
              utilizada por Mercados como por ejemplo: Comerciantes o Locales Comerciales; Transportistas; Funcionarios Municipales, Funcionarios Policiales,
              Mujeres Violentadas, etc. Se debe tener en cuenta que este Sistema actúa independiente de si la Aplicación Móvil <strong>“SOSCLICKLITE”</strong>
              este abierta o no en el smartphone que se le entregará a cada usuario que contrate nuestros servicios. <strong>“SOSCLICKLITE”</strong> es
              parte de la familia de los Sistemas de Seguridad <strong>“SOSCLICK”</strong>.</p>
          </div>
        </div>
        <img src="@/assets/images/Panel.png" loading="lazy" data-w-id="776dd761-e2b0-9844-b7c0-4c0422042138" srcset="@/assets/images/Panel-p-500.png 500w, @/assets/images/Panel-p-800.png 800w, @/assets/images/Panel.png 848w" sizes="100vw" alt="" class="image-3">
        <img src="@/assets/images/Panel.png" loading="lazy" srcset="@/assets/images/Panel-p-500.png 500w, @/assets/images/Panel-p-800.png 800w, @/assets/images/Panel.png 848w" sizes="100vw" alt="" class="image-4">
<!--        <img src="@/assets/images/Cel-3.png" loading="lazy" srcset="@/assets/images/Cel-3-p-500.png 500w, @/assets/images/Cel-3.png 520w" sizes="100vw" alt="" class="image-6"><img src="@/assets/images/Cel-4.png" loading="lazy" alt="" class="image-5">-->
<!--      </div>-->
    </div>

    <div class="cf-flex-section-1 wf-section">
      <div class="cf-flex-1---wrapper">
        <div class="cf-flex-1---container">
          <div data-w-id="1f2c9442-cea6-68e2-7a59-d74984dcf62e" class="cf-flex-1---image-contain"><img src="@/assets/images/locatario.png" loading="lazy" style="opacity:1" data-w-id="1f2c9442-cea6-68e2-7a59-d74984dcf62f" sizes="(max-width: 767px) 90vw, (max-width: 991px) 480px, 40vw" srcset="@/assets/images/locatario.png 500w, @/assets/images/locatario.png 507w" alt="" class="cf-flex-1---image-1"></div>
          <div class="cf-flex-1---text-contain">
            <h2 class="cf-32px-heading"><strong class="cf-benefits-pointer-number">SOSCLICKLITE</strong></h2>
            <p class="cf-18px-paragraph">Es un Sistema de Seguridad preventiva que sirve para todo tipo de Mercados, dentro de los cuales podemos
              destacar los siguientes:<br><br>Comerciantes o Locales Comerciales: Este Sistema le permite a todo comerciante acceder a un sistema
              de alta tecnología y comunicación, el cual le permitirá ante cualquier acto delictual que sufra, acceder a un Servicio que se activará
              por voz, comenzando así a transmitir y grabar al o los delincuentes que estén de frente al comerciante, sin que incluso estos no se
              den cuenta de la activación del sistema, pudiendo incluso activar una Alarma sonora que actuara en forma disuasiva. Además
              el referido sistema le enviará notificaciones de emergencia tanto a sus vecinos, los cuales podrán comunicarse por video llamada o
              concurrir a prestarle la ayuda necesaria, como así también, a seguridad ciudadana comunal o la propia policía.<br><br>Este Sistema
              el Comerciante lo podrá tener inserto tanto en Arnés personal como se muestra en la imagen adjunta, o bien, en un LANYARD personal.
              <br><br>El Sistema<strong> “SOSCLICKLITE” </strong>tal como se había mencionado es un servicio pagado para toda
              persona, comerciante, transportista, etc. </p>
          </div>
        </div>
      </div>
    </div>

    <div data-w-id="a24c461e-e6f2-b9d2-62b4-f93b1a7b7e31" class="cf-benefits-section wf-section">
      <h1 class="heading-4 cf-benefits-pointer-number">Otros Mercados</h1>
      <div class="cf-wrapper benefits-wrapper">
        <div class="cf-benefits-pointers-left">
          <div class="cf-benefits-pointer-item">
            <div class="cf-benefits-pointer-heading-and-number">
              <div class="cf-benefits-pointer-number">01</div>
              <h5 class="cf-case-study-number-text">Adultos Mayores</h5>
            </div>
            <div class="cf-text-block-2 _16-px">La implementación del sistema <strong>“SOSCLICKLITE”</strong> en el mercado de los adultos mayores es
              demasiado importante, ya que les permite acceder a comunicaciones con sus familiares en todo momento, ya sea que estos los llamen, o bien,
              que ellos mediante la voz activen la llamada de video llamada con sus parientes. Todo lo anterior posibilita la integración y comunicación
              en todo momento de estos adultos mayores con sus familiares y viceversa, e incluso para los adultos mayores que se encuentran viviendo solos
              y que no tienen contacto con sus familiares ya sea por abandono, pueden ser monitoreados a distancia por ejemplo por cuidadores del propio Municipio
              o Gobierno a través de la instalación en este caso del Panel WEB, de comunicación total en dependencias de éstas Instituciones.</div>
            <div class="cf-benefits-pointer-line"></div>
          </div>
          <div class="cf-benefits-pointer-item">
            <div class="cf-benefits-pointer-heading-and-number">
              <div class="cf-benefits-pointer-number">02</div>
              <h5 class="cf-case-study-number-text">Activación a distancia de Emergencia. </h5>
            </div>
            <div class="cf-text-block-2 _16-px">Adicionalmente como el sistema <strong>“SOSCLICKLITE”</strong> se activa por voz, no es necesario que el
              smartphone que contiene nuestra Aplicación y que es entregado al usuario sea por ejemplo instalado o integrado ya sea con un collar o arnés,
              sino que incluso puede estar libre, considerando que ante cualquier caída que el Adulto Mayor tenga o acto de emergencia que pueda tener la persona, podrá mediante su voz activar las
              comunicaciones que le posibilitaran acceder a asistencia y comunicación por video llamada ante la emergencia. Esto ha sido probado hasta
              15 metros de distancia entre la persona y el lugar en donde se ubique el Móvil. Cabe consignar que ésta tecnología es de suma importancia
              en especial para los Adultos Mayores que en su mayoría no manejan las actuales Tecnologías de comunicación, considerando que pueden ser activadas
              gracias al sistema <strong>“SOSCLICKLITE”</strong> tan solo con su voz, todo lo cual los integra a una sociedad que en la actualidad los
              tienen bastante abandonados.</div>
            <div class="cf-benefits-pointer-line"></div>
          </div>
        </div>
        <div class="cf-benefits-image"><img src="@/assets/images/Cel-1.png" loading="lazy" srcset="@/assets/images/Cel-1-p-500.png 500w, @/assets/images/Cel-1.png 520w" sizes="(max-width: 991px) 100vw, 32vw" alt="" class="cf-video-thumbnail"></div>
        <div class="cf-benefits-pointers-right">
          <div class="cf-benefits-pointer-item">
            <div class="cf-benefits-pointer-heading-and-number">
              <div class="cf-benefits-pointer-number">03</div>
              <h5 class="cf-case-study-number-text"><strong>Policías y Carabineros</strong></h5>
            </div>
            <div class="cf-text-block-2 _16-px">A través de <strong>“SOSCLICKLITE”</strong>, la jerarquía directa podrá
              obtener imágenes en línea de lo que está sucediendo en tiempo real ante un acto delictual, además el Sistema proporciona una grabación del video lo
              que podría ser utilizado como prueba ante un tribunal.

              Todo lo anterior quedara grabado y las ordenes incluso no las podría tomar el propio policía, sino que el
              personal jerárquico que está en contacto con el funcionario desde la Central. Toda comunicación quedará grabada en tiempo real, pudiendo
              incluso determinar las responsabilidades de las decisiones tomadas por uno u otro funcionario. Para éste caso el smartphone con el Sistema
              <strong>“SOSCLICKLITE”</strong> deberá utilizarlo el Policía a través de un Arnés diagonal tal como se muestra en la imagen precedente.</div>
            <div class="cf-benefits-pointer-line _3"></div>
          </div>
          <div class="cf-benefits-pointer-item">
            <div class="cf-benefits-pointer-heading-and-number">
              <div class="cf-benefits-pointer-number">04</div>
              <h5 class="cf-case-study-number-text">Otros Mercados</h5>
            </div>
            <div class="cf-text-block-2 _16-px">Independiente que el Sistema <strong>“SOSCLICKLITE”</strong> puede ser implementado para otros Mercados
              incluso sin excepción, tales como: Transportistas; Taxistas; Colectiveros; Choferes de Micros; Ambulancias; Mujeres Violentadas o Acosadas
              Sexualmente; Conductores de Vehículos para grabar encerronas por ejemplo; Guardias de Mall; Comunicación con Enfermos Postrados, etc.
              Creemos que un Mercado en el cual este Sistema viene a ser de gran utilidad también es en el cuidado de Adultos Mayores por parte de sus
              propios Familiares e Hijos.</div>
            <div class="cf-benefits-pointer-line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "@/helpers/api/planes";
export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    video: false,
  }),
  mounted() {
    if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
      this.getOrderStatus(this.$route.query.token);
    }
    setTimeout(() => {
      this.togglePlay();
    }, 1000);
  },
  methods: {
    togglePlay() {
      this.playing = !this.playing;
      if (this.playing) {
        this.$refs.videoRef.play();
      } else {
        this.$refs.videoRef.pause();
      }
    },
    changeVideo() {
      this.video = !this.video;
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
            planPayment.flowData &&
            planPayment.flowData.url &&
            planPayment.flowData.token
        ) {
          const payUrl =
              planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.media
              : "",
          fecha: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.date
              : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sosclick-lite.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
