<template>
  <div class="footer wf-section">
    <div class="div-block-15"><img src="@/assets/images/logo-natural.svg" loading="lazy" id="w-node-_3b992282-22c7-738a-2783-9774db46860e-c72c0137" width="186" alt="" class="image-7">
      <a  href="https://www.sosclick.cl/" target="_blank" class="footer-link">Sosclick</a>
      <a  href="https://www.sosclickmujer.com/" target="_blank" class="footer-link">SosclickMujer</a>
      <a  href="https://www.iduam.com/" target="_blank" class="footer-link">IDUAM</a>      
    </div>
    <div class="div-block-13">
      <div class="div-block-14">
        <div class="linea"></div>
      </div>
      <div class="text-block">2023 © Naturalphone S.A. Todos los derechos reservados.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>

a {
  text-decoration: none;
  color: #fff;
}
</style>
